/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import { AppContext } from "~context/AppContext";

import Banner from "~components/Banner";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import { shuffleArray } from "~utils/helpers";

class AboutPageComponent extends Component {
  relatedProducts = [];

  componentDidMount() {
    const { appContext } = this.props;
    const { allShopifyProduct, allMarkdownRemark } = this.props.data;

    appContext.setNavText(`Back`);

    //
    // related products

    const allRelatedProducts = [];

    allShopifyProduct.edges.forEach(({ node }) => {
      if (
        node.handle.toLowerCase().includes(`beanie`) ||
        node.handle === `sticky-pack`
      ) {
        return;
      }

      allMarkdownRemark.edges.forEach(edge => {
        const { frontmatter } = edge.node;

        if (frontmatter.handle === node.handle) {
          node.fm_title = frontmatter.title;
          node.discount = frontmatter.discount;
          node.preorder = frontmatter.preorder;
          node.soldOut = frontmatter.soldOut;
          node.featuredImage = frontmatter.featuredImage;
        }
      });

      if (!node.soldOut) {
        allRelatedProducts.push(node);
      }
    });

    shuffleArray(allRelatedProducts);

    allRelatedProducts.forEach((relatedProduct, index) => {
      const relatedIndex = index;

      if (relatedIndex >= 3) {
        return;
      }

      this.relatedProducts.push(relatedProduct);
    });
  }

  //

  render() {
    const { bannerMedia } = this.props.data.markdownRemark.frontmatter;

    return (
      <>
        <SEO pageTitle="About" pathName="/about" />

        <Layout className="about-page w-full bg-sun-yellow gpu">
          <Banner
            bannerImage={bannerMedia.src.childImageSharp.fluid}
            bannerText={bannerMedia.bannerText}
          />

          <section
            className="animation-appear relative px-6 xs:px-3 pt-8 pb-48 xs:pb-24"
            style={{ animationDelay: `200ms` }}
          >
            {/* <p className="w-3/5 xs:w-full f3 font-medium text-black">
              Beauty’s not just about looking beautiful. It’s about feeling
              beautiful. So beauty products should be products that make you
              feel beautiful. That could be a bronzer, it could be a bag. It
              could be concealer, but sometimes, maybe it’s just chocolate. Good
              for you, and good for the planet. A reflection of who we really
              are, not who we think we should be.
            </p> */}

            <p className="w-3/5 xs:w-full mt-8 f3 font-medium text-black">
              Fluff is for anyone who thinks that the world doesn’t need more
              products, just better products. Anyone who’s sick of bunny
              testing. Anyone who can’t understand why makeup isn’t vegan. Or
              why there’s palm oil in everything. Anyone who is over
              ‘empowertising’ from faceless brands who don’t care. Anyone who
              thinks that the future can be better than what we have right now.
              Anyone who thinks that beauty can be more than makeup.
            </p>
          </section>

          {this.relatedProducts && this.relatedProducts.length > 0 && (
            <section className="products-grid__scroll products-grid__scroll--scroll w-full relative pt-16 pb-32 px-6 bg-off-white">
              <h3 className="products-grid__scroll__heading py-8 f2 text-black font-medium">
                Currently Creating:
              </h3>

              <div className="products-grid__scroll__flex flex flex-row flex-wrap items-stretch justify-between">
                {this.relatedProducts.map((product, index) => {
                  const relatedProductIndex = index;

                  return (
                    <article
                      key={`related-product-${relatedProductIndex}`}
                      className="products-grid__product w-1/3 relative flex flex-col justify-between mb-12 px-6"
                    >
                      {product.discount > 0 && (
                        <div className="products-grid__product__discount absolute top-0 right-0 z-10 px-12 py-3 bg-periwinkle b2 font-medium uppercase text-white">
                          SAVE ${product.discount}
                        </div>
                      )}

                      <Link to={`/products/${product.handle}`}>
                        <div className="products-grid__product__image w-full relative overflow-hidden">
                          <Img
                            className="products-grid__product__image--gatsby"
                            fluid={product.featuredImage.childImageSharp.fluid}
                          />
                        </div>

                        <h3 className="my-2 f4 font-medium text-black">
                          {product.title}
                        </h3>
                      </Link>

                      <Link to={`/products/${product.handle}`}>
                        <button
                          type="button"
                          className="w-full relative block mt-3 py-3 f4 bg-sun-yellow hover:bg-black font-bold text-black hover:text-sun-yellow b-button"
                        >
                          {product.preorder
                            ? `Pre-order: $${product.variants[0].price}`
                            : `Buy Now: $${product.variants[0].price}`}
                        </button>
                      </Link>

                      <h4
                        className="mt-3 b3 font-light text-black"
                        style={{
                          fontSize: `12px`
                        }}
                      >
                        {product.preorder ? `*Ships Jan 31st` : <br />}
                      </h4>
                    </article>
                  );
                })}
              </div>

              <div className="w-full relative flex items-center justify-center ">
                <Link to="/products" className="shopify-product__all block">
                  <button
                    type="button"
                    className="mt-3 py-3 px-6 bg-white b2 uppercase text-black"
                  >
                    Shop All
                  </button>
                </Link>
              </div>
            </section>
          )}
        </Layout>
      </>
    );
  }
}

const AboutPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => <AboutPageComponent appContext={appContext} {...props} />}
    </AppContext.Consumer>
  );
};

export default AboutPage;

export const query = graphql`
  query AbotuPage($id: String!) {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            handle
            discount
            preorder
            soldOut
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1080) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  src
                }
              }
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          variants {
            price
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        bannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
